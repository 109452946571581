import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormToPdfGenerator } from '../form-to-pdf-generator';
import { IFieldGroupFormatter } from './field-group-formatter-interface';

/**
 * Abstract class for a field group formatter
 */
export abstract class FieldGroupFormatter implements IFieldGroupFormatter {
  breadcrumpKeys: string[];
  /**
   * Constructor
   * @param breadcrumpKeys the breadcrump keys chain as array fields where the formatter should be executed
   */
  constructor(breadcrumpKeys: string[]) {
    this.breadcrumpKeys = breadcrumpKeys;
  }
  abstract generateFormatedFieldGroupTag(
    pdfGenerator: FormToPdfGenerator,
    parentField: FormlyFieldConfig | undefined,
    fieldGroup: FormlyFieldConfig[] | undefined,
    breadcrumbKey: string | number | string[] | undefined,
    title: string | undefined
  ): string;
}
