import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormToPdfGenerator } from '../form-to-pdf-generator';
import { FieldGroupFormatter } from './field-group-formatter';
import {
  FORM_TEMPLATE_LEADER,
  FORM_TEMPLATE_ZILE
} from '../../../../funding-project/funding-project-details/tabs/forms/utils/forms-constants';

/**
 * Custom field group formatter for fields groups of custom type 'ExplanationAttachmentField'.
 */
export class FormExplanationAttachmentFieldGroupFormatter extends FieldGroupFormatter {
  private pdfGenerator: FormToPdfGenerator | undefined;
  private parentField: FormlyFieldConfig | undefined;
  private fieldGroup: FormlyFieldConfig[] | undefined;
  private breadcrumbKey: string | number | string[] | undefined;

  generateFormatedFieldGroupTag(
    pdfGenerator: FormToPdfGenerator,
    parentField: FormlyFieldConfig | undefined,
    fieldGroup: FormlyFieldConfig[] | undefined,
    breadcrumbKey: string | number | string[] | undefined,
    title: string | undefined
  ): string {
    let html = '';
    if (pdfGenerator && fieldGroup) {
      this.pdfGenerator = pdfGenerator;
      this.parentField = parentField;
      this.fieldGroup = fieldGroup;
      this.breadcrumbKey = breadcrumbKey;

      html += '<div class="sub-group-wrapper">';
      html += '<h4>Der Antragsteller/Die Antragstellerin erklärt:</h4>';

      const field = this.pdfGenerator.findField(
        parentField ? parentField : fieldGroup,
        breadcrumbKey?.toString() + '.subsidyRequestedForVAT1'
      );
      if (field !== undefined) {
        this.pdfGenerator.getFieldValue(field, breadcrumbKey, undefined);
      }

      // Points
      html += '<table>';
      if (title === FORM_TEMPLATE_LEADER) {
        html += this.generatePointElement(1, this.descriptionPoint1LEADER());
        html += this.generatePointElement(2, this.descriptionPoint2LEADER());
        html += this.generatePointElement(3, this.descriptionPoint3LEADER());
        html += this.generatePointElement(4, this.descriptionPoint4LEADER());
        html += this.generatePointElement(5, this.descriptionPoint5LEADER());
        html += this.generatePointElement(6, this.descriptionPoint6LEADER());
        html += this.generatePointElement(7, this.descriptionPoint7LEADER());
      } else if (title === FORM_TEMPLATE_ZILE) {
        html += this.generatePointElement(1, this.descriptionPoint1ZILE());
        html += this.generatePointElement(2, this.descriptionPoint2ZILE());
        html += this.generatePointElement(3, this.descriptionPoint3ZILE());
        html += this.generatePointElement(4, this.descriptionPoint4ZILE());
        html += this.generatePointElement(5, this.descriptionPoint5ZILE());
        html += this.generatePointElement(6, this.descriptionPoint6ZILE());
        html += this.generatePointElement(7, this.descriptionPoint7ZILE());
        html += this.generatePointElement(8, this.descriptionPoint8ZILE());
        html += this.generatePointElement(9, this.descriptionPoint9ZILE());
      }
      html += '</table>';

      html += '</div>';
    }
    return html;
  }

  /**
   * Get the value for the given/current field by breadcrump key chai.
   *
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   * @param defval the default value
   *
   * @returns the value of the field
   */
  private getFieldValue(
    breakcrumbKey: string,
    defval: string | undefined
  ): string {
    if (this.pdfGenerator) {
      const foundField = this.pdfGenerator.findField(
        this.parentField ? this.parentField : this.fieldGroup,
        this.breadcrumbKey?.toString() + '.' + breakcrumbKey
      );
      if (foundField !== undefined) {
        return this.pdfGenerator.getFieldValue(
          foundField,
          this.breadcrumbKey,
          defval
        );
      }
    }
    return '';
  }

  /**
   * Get a point value tag as string.
   *
   * @param point the number of the point
   * @param description the description of the point
   *
   * @returns the point value tag string
   */
  private generatePointElement(point: number, description: string): string {
    return (
      '<tr><td style="width: 5%; border: none;">' +
      point +
      '.</td><td style="width: 95%; border: none;">' +
      description +
      '</td></tr>'
    );
  }

  /**
   * Get a input element tag as string.
   *
   * @param label the label of the input element
   * @param text the text of the input element
   *
   * @returns the input element tag string
   */
  private generateInputElement(label: string, text: string) {
    return (
      '<div class="input">' +
      '<table style="width: 100%;">' +
      '<tr>' +
      '<td style="width: 100%; margin-bottom: 0px;">' +
      '<table style="width: 100%;">' +
      '<tr>' +
      '<td style="border: none; font-size: 10px; margin-bottom: 0px;">' +
      '<div class="label">' +
      label +
      '</div>' +
      '</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="width: 100%; border: none; margin-left: 15px; margin-bottom: 0px; font-weight: bold;">' +
      '<div class="value">' +
      text +
      '</div>' +
      '</td>' +
      '</tr>' +
      '</table>' +
      '</td>' +
      '</tr>' +
      '</table>' +
      '</div>'
    );
  }

  /**
   * Get the description of point 1.
   *
   * @returns the description point 1
   */
  private descriptionPoint1LEADER(): string {
    return (
      'Soweit eine Förderung von Personal für Regionalmanagement/Geschäftsstelle beantragt wird, ' +
      'das beim Vorhabenträger eingestellt werden soll: Die beim Vorhabenträger geltenen Bestimmungen ' +
      'zu Stellenausschreibungen werden eingehalten.'
    );
  }

  /**
   * Get the description of point 2.
   *
   * @returns the description point 2
   */
  private descriptionPoint2LEADER(): string {
    return (
      'Mit dem Vorhaben ist noch nicht begonnen worden und wird ' +
      'auch vor Bekanntgabe des Zuwendungsbescheides nicht begonnen.' +
      '<br/><br/>' +
      '(Als Vorhabenbeginn ist grundsätzlich der Abschluss eines der ' +
      'Ausführung zuzurechnenden Lieferungs- oder Leistungsvertrages zu werten.)'
    );
  }

  /**
   * Get the description of point 3.
   *
   * @returns the description point 3
   */
  private descriptionPoint3LEADER(): string {
    return (
      'Bei der Vorhabendurchführung werden die Belange der Barrierefreiheit ' +
      'berücksichtigt. Ausnahmen sind gesondert zu begründen.'
    );
  }

  /**
   * Get the description of point 4.
   *
   * @returns the description point 4
   */
  private descriptionPoint4LEADER(): string {
    const description =
      'Wie in Ziffer 3.1 dargestellt, wird für die in Ansatz ' +
      'gebrachte Umsatzsteuer (MwSt.) eine Förderung beantragt. Da kein Anspruch auf die Vorsteuerabzugsberechtigung ' +
      'nach § 15 UStG besteht, wird hierzu die beigefügte Erklärung (Erklärung ' +
      'des/der Begünstigten) abgegeben.';
    const checked = this.getFieldValue('noEntitlement', undefined);
    if (this.pdfGenerator) {
      return this.pdfGenerator.getCheckboxTableTagByValue(checked, description);
    }
    return description;
  }

  /**
   * Get the description of point 5.
   *
   * @returns the description point 5
   */
  private descriptionPoint5LEADER(): string {
    return (
      'Die Vorhabendurchführung erfolgt nicht zur Umsetzung einer ' +
      'gesetzlichen Verpflichtung.'
    );
  }

  /**
   * Get the description of point 6.
   *
   * @returns the description point 6
   */
  private descriptionPoint6LEADER(): string {
    return (
      'Das zur Förderung beantragte Förderobjekt befindet sich in meinem/unserem Eigentum. Soweit ' +
      'ich/wir nicht Eigentümer bin/sind, habe/n ich/wir diesem Antrag eine Einverständniserklärung des ' +
      'Eigentümers über die Durchführung des Vorhabens und die Duldung einer Zweckbindungsfrist ' +
      'beigefügt.'
    );
  }

  /**
   * Get the description of point 7.
   *
   * @returns the description point 7
   */
  private descriptionPoint7LEADER(): string {
    return (
      'Das Vorhaben ist mit den Planungen für die Ver- und Entsorgung ' +
      'abgestimmt.'
    );
  }

  /**
   * Get the description of point 1.
   *
   * @returns the description point 1
   */
  private descriptionPoint1ZILE(): string {
    return (
      'Mit dem Vorhaben ist noch nicht begonnen worden und wird auch vor Bekanntgabe des Zuwendungsbescheides ' +
      'nicht begonnen.' +
      '(Als Vorhabenbeginn ist grundsätzlich der Abschluss eines der Ausführung zuzurechnenden Lieferungs- ' +
      'oder Leistungsvertrages zu werten.)'
    );
  }

  /**
   * Get the description of point 2.
   *
   * @returns the description point 2
   */
  private descriptionPoint2ZILE(): string {
    return (
      'Sofern mit der Vorhabendurchführung die Schaffung von Arbeitsplätzen' +
      'verbunden ist, wird eine geschlechterneutrale Verteilung' +
      'sichergestellt. Sollte dies nicht möglich sein, so erfolgt hierzu' +
      'die Vorlage einer begründenden Unterlage.'
    );
  }

  /**
   * Get the description of point 3.
   *
   * @returns the description point 3
   */
  private descriptionPoint3ZILE(): string {
    return 'Bei der Vorhabendurchführung werden die Belange der Barrierefreiheit berücksichtigt. Ausnahmen sind gesondert zu begründen.';
  }

  /**
   * Get the description of point 4.
   *
   * @returns the description point 4
   */
  private descriptionPoint4ZILE(): string {
    const description =
      'Nur Gemeinden und Gemeindeverbände: Wie im Abschnitt Finanzierungsplan dargestellt, wird für die in Ansatz gebrachte Umsatzsteuer (MwSt.) eine Förderung beantragt. Da kein Anspruch auf die Vorsteuerabzugsberechtigung nach § 15 UStG besteht, wird hierzu die beigefügte Erklärung (Erklärung des/der Begünstigten) abgegeben.';
    const checked = this.getFieldValue('subsidyRequestedForVAT1', undefined);
    if (this.pdfGenerator) {
      return this.pdfGenerator.getCheckboxTableTagByValue(checked, description);
    }
    return description;
  }

  /**
   * Get the description of point 5.
   *
   * @returns the description point 5
   */
  private descriptionPoint5ZILE(): string {
    const description =
      'Nur Teilnehmergemeinschaften nach dem Flurbereinigungsgesetz: Wie im Abschnitt Finanzierungsplan dargestellt, wird für die in Ansatz gebrachte Umsatzsteuer (MwSt.) eine Förderung beantragt. Als Teilnehmergemeinschaft nach dem FlurbG wird hierzu die beigefügte Erklärung (Erklärung der/des Begünstigten) abgegeben und als Anlage beigefügt. Die Vorlage einer Bescheinigung eines unabhängigen Dritten ist daher nicht erforderlich.';
    const checked = this.getFieldValue('subsidyRequestedForVAT2', undefined);
    if (this.pdfGenerator) {
      return this.pdfGenerator.getCheckboxTableTagByValue(checked, description);
    }
    return description;
  }

  /**
   * Get the description of point 6.
   *
   * @returns the description point 6
   */
  private descriptionPoint6ZILE(): string {
    return 'Die Vorhabendurchführung erfolgt nicht zur Umsetzung einer gesetzlichen Verpflichtung.';
  }

  /**
   * Get the description of point 7.
   *
   * @returns the description point 7
   */
  private descriptionPoint7ZILE(): string {
    return (
      'Ich/Wir bin/sind Eigentümer der zur Förderung beantragten Anlage/n.' +
      'Soweit ich/wir nicht Eigentümer bin/sind, habe/n ich/wir diesem' +
      'Antrag eine Einverständniserklärung des Eigentümers über die' +
      'Durchführung des Vorhabens und die Duldung einer Zweckbindungsfrist' +
      'beigefügt.'
    );
  }

  /**
   * Get the description of point 8.
   *
   * @returns the description point 8
   */
  private descriptionPoint8ZILE(): string {
    return (
      'Das als Anlage beigefügte Informationsblatt nach der' +
      'Datenschutz-Grundverordnung habe ich/wir zur Kenntnis genommen.'
    );
  }

  /**
   * Get the description of point 9.
   *
   * @returns the description point 9
   */
  private descriptionPoint9ZILE(): string {
    return 'Das Vorhaben ist mit den Planungen für die Ver- und Entsorgung abgestimmt.';
  }
}
